.dg-input-error {
  color: red;
  display: none;
}

.dg-input input:invalid[focused="true"],
.dg-input select:invalid[focused="true"] {
  border: 1px solid red;
}

.dg-input input:invalid[focused="true"] ~ .dg-input-error,
.dg-input select:invalid[focused="true"] ~ .dg-input-error {
  display: block;
}

.dg-input input:disabled {
  background-color: #d6d6d6 !important;
  cursor: not-allowed;
}
