@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  font-family: "Open Sans", sans-serif;
}

body {
  font-family: "Open Sans", sans-serif;
}

.App {
  text-align: center;
}

.sideBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 13rem;
  height: 100vh;
  background: #ffffff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.menuTop {
  height: 50vh;
}

.cssLoginIzq {
  background: transparent url(css/img/bgLogin.png) no-repeat center / cover;
  position: fixed;
  width: 50%;
  height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
}

.cssLoginDer {
  background: #ffffff no-repeat center / cover;
  box-shadow: -5px -5px 10px rgba(150, 150, 150, 0.2);
  position: fixed;
  width: 50%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  right: 0;
  overflow-y: auto;
}

.cssInput {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  background-color: #f9f9f9 !important;
  border: none;
}

.cssInput:focus {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px !important;
  background-color: #f9f9f9 !important;
  border: none;
}

.iconInput {
  border-right: solid 2px #6b6b6b;
  padding: 0 0.5rem;
  height: 25px !important;
  margin: 1.25rem 0.25rem;
}

.cssButton {
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.cssLink {
  text-decoration: none;
  cursor: pointer;
}

.topBar {
  z-index: 99;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}

.cssUserName {
  border-bottom: solid 1px #565656;
  margin-right: 0.5rem;
  margin-bottom: 0;
  padding-bottom: 0.15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cssUserRole {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  width: 100%;
}
.MuiTableCell-head {
  color: white !important;
}
.menuItem {
  display: flex;
  align-items: center;
  transition: 0.5s;
}
.menuItem:hover {
  background-color: #2a2f99 !important;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  transition: 0.5s;
}
.menuItem:hover svg {
  stroke: white !important;
  transition: 0.5s;
}
.menuItem:hover span {
  color: white !important;
  transition: 0.5s;
}

.itemSubmenu {
  color: #2a2f99 !important;
  justify-content: flex-start !important;
}
.tabsDetalleC {
  min-width: initial !important;
  max-width: initial !important;
  width: 2.2rem !important;
  padding: 0rem 0.1rem !important;
}
.titulosDetalle {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2a2f99 !important;
  text-transform: uppercase;
}

.container {
  max-width: 100% !important;
  padding-left: 15rem !important;
  padding-right: 2rem !important;
}
.pageTitle{
  text-align: center;
    padding-left: 13rem;
    width: 75%;
    font-size: 1.6rem;
    font-weight: 700;
    color: #2a2f99;
    text-transform: uppercase;
}
svg.m-auto.action-table{
  cursor: pointer !important;
  margin: 0rem 0.3rem !important;
}
